import React from 'react';

function App() {
  const value = 'World';


  return <div> please vist my website www.yahoo.com Hello to my first java script page  {value}</div>;
}

export default App;
